import React from 'react';
import '../styles/Adulte.css';
import { Link } from 'react-router-dom';

import modernJazzImage from '../Images/modernejazzhero.png';
import danseOrientaleImage from '../Images/oriental-1024x549.jpg';
import chantImage from '../Images/chant1.jpg';
import theatreImage from '../Images/theatreadulte.jpg';
import salsaImage from '../Images/jazz6.jpg';
import anniversaireImage from '../Images/birth.jpg'; // Add the image for the new category
import gymdouce from '../Images/douce1.jpg';
import gymdouce2 from '../Images/douce2.jpg';
import Barreausol from '../Images/barreausol.jpg'
import dansedumonde from '../Images/dansedumondeadulte.png';
import Bachata from '../Images/salsa1.png';

const categories = [
  {
    title: 'Modern Jazz',
    description: 'Explorez le style énergique et expressif du Modern Jazz.',
    image: modernJazzImage,
    link: '/ModernJazz'
  },
  {
    title: 'Danse Orientale',
    description: 'Immergez-vous dans les mouvements gracieux et rythmiques de la Danse Orientale.',
    image: danseOrientaleImage,
    link: '/DanseOrientale'
  },
  {
    title: 'Chant',
    description: 'Développez vos compétences vocales et profitez de l\'art du chant.',
    image: chantImage,
    link: '/chant'
  },
  {
    title: ' Théâtre',
    description: 'Libérez votre potentiel d\'acteur avec nos ateliers de théâtre.',
    image: theatreImage,
    link: '/AthelierDeTheatre'
  },
  {
    title: 'Salsa Cubaine',
    description: 'Ressentez le rythme et la joie de la Salsa Cubaine.',
    image: salsaImage,
    link: '/SalsaCubaine'
  },
  {
    title: 'Gym Douce',
    description: 'Découvrez la gym douce, activité parfaite pour améliorer votre souplesse et votre bien-être en toute sérénité!',
    image: gymdouce,
    link: '/Gymdouce'
  }
  ,
  {
    title: 'Yoga',
    description: 'Célébrez votre anniversaire avec une fête inoubliable!',
    image: gymdouce2,
    link: '/Yoga'
  },
 
  {
    title: 'Danse du Monde',
    description: 'Plongez dans la danse du monde et explorez des rythmes et des cultures variés pour une expérience enrichissante et énergisante!',
    image: dansedumonde,
    link: '/Dansedumonde'
  },
  {
    title: 'Bachata',
    description: 'Découvrez la bachata, une danse passionnée et envoûtante qui vous transportera au cœur des rythmes latinos!',
    image: Bachata,
    link: '/Bachta'
  },
  {
    title: 'Pilates',
    description :'Le pilates vise à renforcer vos muscles profonds en douceur. Que vous soyez plus ou moins sédentaire, ce cours vous permettra d’améliorer vos gestes et vos postures dans toutes les situations possibles du quotidien',
  // image : ,
   link:'/Pilate',
  }
];

const Adulte = () => {
  return (
    <div>
      <main className="adulte-main">
        <h1 className="adulte-title">Activités adultes</h1>
        <div className="categories-container">
          {categories.map((category, index) => (
            <div className="category-card" key={index}>
              <div className="image-overlay">
                <img src={category.image} alt={category.title} className="category-image" />
              </div>
              <div className="category-content">
                <h2 className="category-title">{category.title}</h2>
                <p className="category-description">{category.description}</p>
                <div className="category-buttons">
                  <Link to={category.link} className="category-button">Découvrir</Link>
                  <a href="Schedule" className="category-button reserve">Réserver</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Adulte;