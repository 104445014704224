import React from 'react';
import '../styles/Category.css';
import '../styles/type.css';
import '../styles/Gallery.css';
//import modernJazzVideo from '../Videos/Danse Orientale.mp4';
import sampleImage from '../Images/oriental-1024x549.jpg'; // Update the path to the image
import sampleImage7 from '../Images/Loundjah444(1).jpg'; // Update the path to the image

import sampleImage2 from '../Images/oriental.jpg'; // Update the path to the image
import newImage from '../Images/Purple Boudoir Way of Life Ophélie Rahif Photo Margaux Rodrigues (5).jpg'; // Update the path to the image
import service26Image1 from '../Images/oriental-1024x549.jpg'; // Add this line
import service26Image2 from '../Images/oriental.jpg'; // Add this line
import galleryImage1 from '../Images/jazz.jpg'; // Update the path as needed
import galleryImage2 from '../Images/birth.jpg'; // Update the path as needed
import galleryImage3 from '../Images/salsa.jpg'; // Update the path as needed
import galleryImage4 from '../Images/danse gregoire.png'; // Update the path as needed
import galleryImage5 from '../Images/danse gregoire.png'; // Update the path as needed
import galleryImage6 from '../Images/Adil-Djibouli-3973-scaled-1-150x150.jpg'; // Update the path as needed
import galleryImage7 from '../Images/Anne-sophie-150x150.jpg'; // Update the path as needed
import galleryImage8 from '../Images/birth.jpg'; // Update the path as needed

const Pilate = () => {
  return (
    <div className="category-page">
    <section className="hero-section">
      <video className="hero-video" autoPlay muted loop>
        Your browser does not support the video tag.
      </video>
      <div className="hero-overlay">
  <h1 className="hero-title">Pilate</h1>
  <p className="hero-description"> Ce cours vous permettra d’améliorer vos gestes et vos postures dans toutes les situations possibles du quotidien.</p>
</div>
</section>
<div className="py-5 service-12">
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <h4 className="my-3">Vous aimez les mouvements élégants et sensuels ? Inscrivez-vous à notre cours de Pilate.</h4>
        <h6 className="font-weight-light subtitle">C’est une activité idéale pour améliorer la flexibilité, la force et la confiance en soi.</h6>
        <div className="row">
          <div className="col-md-6 mt-3">
            <h6 className="font-weight-medium">Renforcement Musculaire</h6>
            <p>Le pilates est une méthode complète qui permet de renforcer en profondeur vos muscles. Grâce à des mouvements précis et contrôlés, vous travaillerez les muscles stabilisateurs tout en améliorant votre posture. Que vous soyez débutant ou expérimenté, cette pratique saura s'adapter à votre niveau pour un renforcement efficace et harmonieux.</p>
          </div>
          <div className="col-md-6 mt-3">
            <h6 className="font-weight-medium">Équilibre et Flexibilité</h6>
            <p>Le pilates ne se limite pas seulement au renforcement musculaire. Il inclut également des exercices visant à développer votre souplesse et votre équilibre. Les mouvements fluides, effectués sur un tapis, permettent de relâcher les tensions tout en augmentant votre amplitude de mouvement, favorisant ainsi une meilleure mobilité globale.</p>
          </div>
          <div className="col-md-6 mt-3">
            <h6 className="font-weight-medium">Bien-être et Sérénité</h6>
            <p>Avec le pilates, vous cultiverez un sentiment de bien-être et de sérénité. Les exercices, tout en douceur, combinent respiration et concentration, vous aidant ainsi à vous recentrer. À long terme, cette pratique devient un rituel qui allie bien-être physique et mental, pour une harmonie totale du corps et de l’esprit.</p>
          </div>
          <div class="col-md-6 mt-3">
            <h6 class="font-weight-medium">Amélioration de la Posture</h6>
            <p>La pratique régulière du pilates vous aidera à corriger vos mauvaises postures et à adopter une position plus saine au quotidien. Les exercices se concentrent sur l’alignement du corps et la coordination, ce qui en fait une solution idéale pour celles et ceux qui cherchent à prévenir les douleurs dorsales et à mieux se tenir.</p>
          </div>
              <div className="col-lg-12 my-4">
                <a className="btn btn-info-gradiant btn-md border-0 text-white" href="Schedule"><span>Réserver</span></a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row wrap-service12">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 img-hover mb-4">
                  </div>
                  <div className="col-md-12 img-hover mb-4">
                  </div>
                </div>
              </div>
              <div className="col-md-6 uneven-box">
                <div className="row">
                  <div className="col-md-12 img-hover mb-4">
                  </div>
                  <div className="col-md-12 img-hover mb-4">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Pricing Section */}
    <div className="pricing8 py-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 text-center">
        <h3 className="mb-3">Tarification pour rendre votre travail efficace</h3>
        <h6 className="subtitle font-weight-normal">Nous offrons une satisfaction à 100% et une garantie de remboursement</h6>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-4 ml-auto pricing-box align-self-center">
        <div className="card mb-4">
          <div className="card-body p-4 text-center">
            <h5 className="font-weight-normal">Carte 10 cours</h5>
            <sup>€</sup><span className="text-dark display-5">200</span>
            <h6 className="font-weight-light font-14">TARIF UNIQUE</h6>
            <p className="mt-4">Accédez à 10 cours de votre choix. Idéal pour ceux qui veulent un engagement flexible.</p>
          </div>
          <a className="btn btn-info-gradiant p-3 btn-block border-0 text-white" href="Schedule">CHOISIR</a>
        </div>
      </div>
      <div className="col-md-4 ml-auto pricing-box align-self-center">
        <div className="card mb-4">
          <div className="card-body p-4 text-center">
            <h5 className="font-weight-normal">Cours individuel</h5>
            <sup>€</sup><span className="text-dark display-5">22</span>
            <h6 className="font-weight-light font-14">PAR COURS</h6>
            <p className="mt-4">Un cours unique pour découvrir notre programme. Parfait pour les nouveaux venus.</p>
          </div>
          <a className="btn btn-danger-gradiant p-3 btn-block border-0 text-white" href="Schedule">CHOISIR</a>
        </div>
      </div>
      <div className="col-md-4 ml-auto pricing-box align-self-center">
        <div className="card mb-4">
          <div className="card-body p-4 text-center">
            <h5 className="font-weight-normal">Abonnement annuel</h5>
            <sup>€</sup><span className="text-dark display-5">520</span>
            <h6 className="font-weight-light font-14">1 COURS/SEMAINE</h6>
            <p className="mt-4">Accédez à un cours par semaine pendant un an. Idéal pour une pratique régulière.</p>
          </div>
          <a className="btn btn-info-gradiant p-3 btn-block border-0 text-white" href="Schedule">CHOISIR</a>
        </div>
      </div>
    </div>
  </div>
</div>


    {/* Service 26 Section */}
    <div className="py-5 service-26">
  <div className="container">
    <div className="row wrap-service-26">
      <div className="col-md-7 align-self-center">
        <div className="max-box">
          <h3 className="mt-3">Renforcement en Douceur pour un Quotidien Plus Harmonieux</h3>
          <p className="mt-3">Le pilates vise à renforcer vos muscles profonds en douceur. Que vous soyez plus ou moins sédentaire, ce cours vous permettra d’améliorer vos gestes et vos postures dans toutes les situations possibles du quotidien : aussi bien à la maison que lors des déplacements en extérieur (travail, transports en commun, etc.). Excluant tout mouvement brusque, se pratiquant sur un tapis, ce cours peut être idéal pour une reprise d’activité physique. A plus long terme, il peut aussi se tourner davantage vers une « routine » qui vous permettra de franchir à travers les options d’autres niveaux. 
          </p>
        </div>
      </div>
      <div className="col-md-5">
      </div>
    </div>
    <div className="row wrap-service-26 mt-4 pt-3">
      <div className="col-md-6">
      </div>
      <div className="col-md-6 align-self-center">
        <h3 className="mt-3">Approche Hybride pour un Entraînement Personnalisé</h3>
        <p className="mt-3">Adrien vous propose une à trois alternatives pour chacun des exercices, avec des mouvements issus du répertoire original de Joseph Pilates mais présenté de manière « hybride ». Vous travaillerez par cycles autour de différentes thématiques telles que la respiration, le centrage (la partie abdominale), la fluidité,.</p>
      </div>
    </div>
  </div>

<div class="cards">
     
       <div class="services">
        
          <div class="content content-2">
             <div class="fab fa-instagram"></div>
             <h2>
                Instagram
             </h2>
             <p>
             Découvrez le monde visuel vibrant de l'Espace Balard sur Instagram. Suivez-nous pour des photos magnifiques, des aperçus des coulisses et du contenu créatif qui met en valeur notre atmosphère unique et nos événements. Soyez inspiré par nos histoires et connectez-vous avec nous à travers vos clichés préférés 
             </p>
             <a
                  href="https://www.instagram.com/espacebalard_fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Découvrir
                </a>           </div>
          <div class="content content-3">
             <div class="fab fa-youtube"></div>
             <h2>
                Youtube
             </h2>
             <p>

             Plongez dans le contenu vidéo dynamique de l'Espace Balard sur YouTube. Regardez des vidéos captivantes, des temps forts d'événements, des tutoriels et bien plus encore. Abonnez-vous à notre chaîne pour ne rien manquer de nos dernières publications et découvrez tout ce que nous avons à offrir             </p>
             <a
                  href="https://www.youtube.com/@espacebalard2791/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Découvrir
                </a>            </div>
       </div>
    </div>
    </div>
  </div>
  
);
};
export default Pilate;
