import React from 'react';
import '../styles/Home.css';  // Ensure the path to Home.css is correct
import sampleImage2 from '../Images/kids.jpeg'; // Update the path to the image
import sampleImage from '../Images/adulte (2).jpeg'; // Update the path to the image
import newImage from '../Images/birthday.jpg'; // Update the path to the new image

const Home = () => {
  return (
    <div className="home">
      <div className="intro">
        <h1>Centre Espace Balard </h1>
        <p>            A deux pas du métro Lourmel, dans le 15ème arrondissement de Paris, notre studio vous accueille dans un bel espace de 80 m2, calme et lumineux pour pratiquer la danse, le yoga ou le théâtre auprès de professeurs enthousiastes et compétents...
        </p>
      </div>
      <div className="section">
        <div className="text-container">
          
          <h1>  Cours  pour Adultes</h1>
          <p>
            <br></br> <br></br> 
            Le centre Espace Balard Paris 15 propose plusieurs ateliers pour adultes et Seniors

            Au programme Yoga, Gym douce, ou Pilates

            3h de cours de Théâtre par semaine

              des cours de danse tous niveau salsa, bachata , danse orientale, Modern Jazz ...
 
 
          </p>
          <div className="buttons-container">
            <a href="Schedule" className="home-btn">Découvrir</a>
            <a href="Schedule" className="home-btn1">Réserver</a>
          </div>
        </div>
        <div className="image-container">
          <a href="/schedule">
          <img src={sampleImage} alt="Activities" href="/schedule" />
          </a> 
        </div>
      </div>
      
      <div className="section">
        <div className="image-container">
        <a href="/schedule">

          <img src={sampleImage2} alt="Activities" />
          </a> 
        </div>
        <div className="text-container">
          
          <h1>Cours pour Enfants </h1>
          <p>
            <br></br> <br></br>
            Le centre Espace Balard Paris 15 propose plusieurs ateliers et stages pour enfants tout au long de l'année scolaire.
            <br></br>Découvrez notre programme pour cette année.
          </p>
          <div className="buttons-container">
            <a href="Schedule" className="home-btn">Découvrir</a>
            <a href="Schedule" className="home-btn1">Réserver</a>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="text-container3">
          <h1>Organisez Votre Événement: Anniversaire ou Location d'Espace! </h1>
          <p>
            <br></br> <br></br>
            Faites de votre événement un moment inoubliable à l'Espace Balard ! Que vous souhaitiez célébrer un anniversaire mémorable ou louer notre espace polyvalent pour des occasions spéciales, nous sommes là pour répondre à tous vos besoins. Notre salle spacieuse et bien équipée est idéale pour accueillir des fêtes, des réunions familiales, des soirées dansantes, et bien plus encore.
  <br></br> <br></br>
  
          </p>
          <div className="buttons-container">
            <a href="Studio" className="home-btn">Explorer</a>
            <a href="Studio" className="home-btn1">S'inscrire</a>
          </div>
        </div>
        <div className="image-container">
          <img src={newImage} alt="New Activities" />
        </div>
      </div>
      
      <div className="map-section">
        <div className="map-container">
          <iframe
            title="Espace Balard Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.7395857637357!2d2.278579011726066!3d48.836780211230696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6707347f760b9%3A0xb3a1447cbe834816!2sESPACE%20BALARD!5e0!3m2!1sfr!2sfr!4v1716230388340!5m2!1sfr!2sfr"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="map-description">
          <h2>ESPACE BALARD</h2>
          <p>
            Découvrez notre Salle de Danse Événementielle à Paris 15 : Votre Espace Créatif Privé ! 🎶
Superficie: 80m2 Situation: Rez-de-chaussée Sol : Tapis de danse Vestiaires et sanitaires System d’aération 
            <br />
            354 Rue Lecourbe , Paris, 75015
            <br />
            transport public pour y arriver a compléter
          </p>
          <a href="Studio" className="home-btn">DÉCOUVRIR LE STUDIO</a>
        </div>
      </div>
    </div>
  );
};

export default Home;
