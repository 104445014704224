import React, { useState } from 'react';
import ScheduleDay from '../components/ScheduleDay';
import '../styles/Schedule.css';
import SlotDetailsModal from '../components/SlotDetailsModal';

const days = [
  { name: 'LUN', date: '24', slots: [
    { time: '15:00', duration: '60 min', course: 'Chant', instructor: 'Anne-Sophie', description: 'Cours de chant pour tous les niveaux.', targetAudience: 'adults' },
    { time: '12:15', duration: '60 min', course: 'Pilate', instructor: '', description: 'Adapté à tous les niveaux.', targetAudience: 'adults' },
    { time: '19:15', duration: '60 min', course: 'Bachata', instructor: 'Gabrielé', description: 'Cours de salsa cubaine pour débutants.', targetAudience: 'adults' },
    { time: '17:00', duration: '60 min', course: 'Chant Enfants', instructor: 'Anne-Sophie', description: 'Cours de chant pour les enfants de 7 ans et plus.', targetAudience: 'kids' },
  ]},
  { name: 'MAR', date: '25', slots: [
    { time: '14:30', duration: '2h', course: 'Théâtre', instructor: 'ZAKIAA', description: 'Cours de théâtre pour tous les niveaux.', targetAudience: 'adults' },
    { time: '12:15', duration: '60 min', course: 'Danse talon', instructor: 'Julie', description: 'Cours de danse modern jazz.', targetAudience: 'adults' },
    { time: '17:00', duration: '60 min', course: 'Danse Modern Jazz Enfants', instructor: 'Julie', description: 'Cours de danse modern jazz pour les enfants.', targetAudience: 'kids' },
  ]},
  { name: 'MER', date: '26', slots: [
    { time: '19:00', duration: '60 min', course: 'Danse Modern Jazz', instructor: 'Julie', description: 'Cours de danse modern jazz de niveau 1.', targetAudience: 'adults' },
    { time: '10:30', duration: '90 min', course: 'Théâtre Enfants', instructor: 'Zakia', description: 'Atelier de théâtre pour les enfants.', targetAudience: 'kids' },
    { time: '18:00', duration: '75 min', course: 'Danse Modern Jazz Enfants', instructor: 'Instructor M', description: 'Cours de danse modern jazz pour les adolescents.', targetAudience: 'kids' },
  ]},
  { name: 'JEU', date: '27', slots: [
    { time: '20:00', duration: '3h', course: 'Théâtre', instructor: 'ZAKIAA', description: 'Cours de théâtre pour tous les niveaux.', targetAudience: 'adults' },
    { time: '19:00', duration: '60 min', course: 'Danse Orientale', instructor: 'Loundjah', description: 'Cours de danse orientale pour débutants.', targetAudience: 'adults' },
    { time: '17:00', duration: '60 min', course: 'Danse Classique Enfants', instructor: 'Instructor Q', description: 'Cours de danse classique pour les enfants.', targetAudience: 'kids' },
  ]},
  { name: 'VEN', date: '28', slots: [
    { time: '19:15', duration: '60 min', course: 'Salsa Cubaine', instructor: 'Gabrielé', description: 'Cours de salsa cubaine pour débutants.', targetAudience: 'adults' },
    { time: '20:15', duration: '60 min', course: 'Salsa Cubaine', instructor: 'Gabrielé', description: 'Cours de salsa cubaine de niveau intermédiaire.', targetAudience: 'adults' },
    { time: '18:00', duration: '60 min', course: 'Hip Hop Enfants', instructor: 'ZAKIA', description: 'Cours de hip hop pour les enfants de 7 ans et plus.', targetAudience: 'kids' },
    { time: '18:00', duration: '60 min', course: 'Chant Enfants', instructor: 'Anne-Sophie', description: 'Cours de chant pour les enfants de 7 ans et plus.', targetAudience: 'kids' },
  ]},
  { name: 'SAM', date: '29', slots: [
    { time: '09:15', duration: '60 min', course: 'Yoga', instructor: 'Annick', description: 'Séance de yoga pour la détente et le bien-être.', targetAudience: 'adults' },
    { time: '12:45', duration: '60 min', course: 'Danse Orientale', instructor: 'Loundjah', description: 'Cours de danse orientale pour débutants.', targetAudience: 'adults' },
    { time: '11:15', duration: '75 min', course: 'Théâtre Enfants', instructor: 'ZAKIA', description: 'Atelier de théâtre pour les enfants de 5 à 6 ans.', targetAudience: 'kids' },
    { time: '14:15', duration: '90 min', course: 'Théâtre Enfants', instructor: 'ZAKIA', description: 'Atelier de théâtre pour les enfants de 8 à 10 ans.', targetAudience: 'kids' },
    { time: '15:45', duration: '90 min', course: 'Théâtre Enfants', instructor: 'ZAKIA', description: 'Atelier de théâtre pour les adolescents.', targetAudience: 'kids' },
    { time: '10:15', duration: '60 min', course: 'Danse chorégraphie', instructor: 'Instructor X', description: 'Atelier de chorégraphie pour les enfants de 5 à 6 ans.', targetAudience: 'kids' },
    { time: '16:00', duration: '60 min', course: 'Danse chorégraphie', instructor: 'Instructor X', description: 'Atelier de chorégraphie pour les enfants de 4 à 5 ans.', targetAudience: 'kids' },
    { time: '17:00', duration: '60 min', course: 'Hip Hop Enfants', instructor: 'Adil', description: 'Cours de hip hop pour les enfants de 7 ans et plus.', targetAudience: 'kids' },
    { time: '18:00', duration: '60 min', course: 'Hip Hop Enfants', instructor: 'Adil', description: 'Cours de hip hop pour les adolescents.', targetAudience: 'kids' },
  ]},
];

function Schedule() {
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedAudience, setSelectedAudience] = useState('');

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleAudienceChange = (event) => {
    setSelectedAudience(event.target.value);
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
  };

  const handleCloseModal = () => {
    setSelectedSlot(null);
  };

  const filteredDays = days.map(day => ({
    ...day,
    slots: day.slots
      .filter(slot => 
        (!selectedCourse || slot.course === selectedCourse) && 
        (!selectedAudience || slot.targetAudience === selectedAudience)
      )
      .sort((a, b) => a.time.localeCompare(b.time))
  }));

  const uniqueCourses = [...new Set(days.flatMap(day => day.slots.map(slot => slot.course)))];

  return (
    <div className="schedule-container">
      <div className="planning-header">
        <h1>PLANNING</h1>
        <div className="filter">
          <label htmlFor="course">Séance:</label>
          <select id="course" value={selectedCourse} onChange={handleCourseChange}>
            <option value="">Toutes les séances</option>
            {uniqueCourses.map(course => (
              <option key={course} value={course}>{course}</option>
            ))}
          </select>

          <label htmlFor="audience">Public:</label>
          <select id="audience" value={selectedAudience} onChange={handleAudienceChange}>
            <option value="adults">Adultes</option>
            <option value="kids">Enfants</option>
          </select>
        </div>
      </div>

      <div className="schedule">
        {filteredDays.map(day => (
          <ScheduleDay key={day.date} day={day} onSlotClick={handleSlotClick} />
        ))}
      </div>
      
      {selectedSlot && <SlotDetailsModal slot={selectedSlot} onClose={handleCloseModal} />}
    </div>
  );
}

export default Schedule;
