import '../styles/Hero.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import backgroundImage1 from '../Images/theatre5.png';
import backgroundImage2 from '../Images/salsa1.png';
import backgroundImage3 from '../Images/jazz6.jpg';
import backgroundImage4 from '../Images/oriental-1024x549.jpg';
import backgroundImage5 from '../Images/modernejazzhero.png';

import backgroundImage6 from '../Images/douce2.jpg';
import backgroundImage7 from '../Images/gymdouce.png';
import backgroundImage8 from '../Images/douce2.jpg';
import backgroundImage9 from '../Images/HIPHOP.jpg';

class TxtType {
  constructor(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
  }

  tick() {
    const i = this.loopNum % this.toRotate.length;
    const fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = `<span class="wrap">${this.txt}</span>`;

    let delta = 700;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(() => {
      this.tick();
    }, delta);
  }
}

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
    backgroundImage4,
    backgroundImage5,
    backgroundImage6,
    backgroundImage7,
    backgroundImage8,
    backgroundImage9,

  ];

  const descriptions = [
    {
      header: 'Cours de Théâtre',
      text: '',
    },
    {
      header: 'Cours de Bachata',
      text: '',
    },
    {
      header: 'Cours de Salsa Cubaine',
      text: '',
    },
    {
      header: 'Cours de Dance Oriental',
      text: '',
    },
    {
      header: 'Cours de Moderne Jazz',
      text: '',
    },
    {
      header: 'Cours de Yoga',
      text: '',
    },
    {
      header: 'Cours de Gym douce',
      text: '',
    },
    {
      header: 'Cours de Pilates',
      text: '',
    },
    {
      header: 'Cours de HIP-HOP',
      text: '',
    },
    
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 4000);

    const elements = document.getElementsByClassName('typewrite');
    for (let i = 0; i < elements.length; i++) {
      const toRotate = elements[i].getAttribute('data-type');
      const period = elements[i].getAttribute('data-period');
      if (toRotate) {
        new TxtType(elements[i], JSON.parse(toRotate), period);
      }
    }

    // Inject CSS for typewrite effect
    const css = document.createElement('style');
    css.type = 'text/css';
    css.innerHTML = '.typewrite > .wrap { border-right: 0.08em solid transparent}';
    document.body.appendChild(css);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className="static-slider7"
      style={{
        backgroundImage: `url(${images[currentImage]})`,
        transition: 'background-image 1s ease-in-out',
      }}
    >
      <div className="content-container">
        <h1 className="hero-header">{descriptions[currentImage].header}</h1>
        <p className="hero-text">{descriptions[currentImage].text}</p>
        <Link to="/Schedule" className="home-btn">Réserver</Link>
      </div>
    </div>
  );
};

export default Hero;
