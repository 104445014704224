import React from 'react';
import '../styles/Team.css';

import t1 from '../Images/ZAKIA.jpg';
import t2 from '../Images/Bertrand COMBE.jpg';
import t3 from '../Images/Annick Bergdoll.jpg';
import t4 from '../Images/Julie.jpg';
import t5 from '../Images/Adil-Djibouli-3973-scaled-1-150x150.jpg';
import t6 from '../Images/Anne-sophie-150x150.jpg';
import t7 from '../Images/Loundjah444.jpg';
import t9 from '../Images/prof balard .jpg'
import t8 from '../Images/Sans titre10.png';

const Team = () => {
  const teamMembers = [
    {
      imgSrc: t1,
      name: "Zakia",
      title: "Comédienne",
      description: "En 2006, après une carrière dans l’Assurance, Zakia décide de tout arrêter pour se consacrer entièrement à sa passion première : le théâtre. Elle intègre alors le cours de Jean Laurent COCHET où elle passe quatre années à travailler les scènes du répertoire classique sous l’égide du grand Maître.",
      socialLinks: [
        { href: "#", icon: "icon-social-facebook" },
        { href: "#", icon: "icon-social-twitter" },
        { href: "#", icon: "icon-social-instagram" },
      ]
    },
    {
      imgSrc: t2,
      name: "Bertrand COMBE",
      title: "Comédien",
      description: "Tout commence à la fin des années 90, alors que je suis des cours d’art dramatique à l’école Jean Périmony. Nous décidons avec d’autres élèves de remonter une pièce qui avait connu un beau succès dans les années 80 « Comment devenir une mère juive en 10 leçons » de Paul Fuks, mise en scène Jean-Paul Bazziconi. Ce sera le début d’une longue aventure qui durera 6 ans et comptera plus de 1700 représentations.",
      socialLinks: [
        { href: "#", icon: "icon-social-facebook" },
        { href: "#", icon: "icon-social-twitter" },
        { href: "#", icon: "icon-social-instagram" },
      ]
    },
    {
      imgSrc: t3,
      name: "Annick Bergdoll",
      title: "Professeur de yoga",
      description: "Diplomée de l’école française de yoga en 2010. Elle a été l’élève d’Yvonne Millerand, puis de Béatrice Millerand-Bouchillou. Depuis 10 ans, elle poursuit l’enseignement traditionnel de celles-ci, en proposant des postures et des exercices de respiration adaptés à chacun, débutant ou confirmé.",
      socialLinks: [
        { href: "#", icon: "icon-social-facebook" },
        { href: "#", icon: "icon-social-twitter" },
        { href: "#", icon: "icon-social-instagram" },
      ]
    },
    {
      imgSrc: t4,
      name: "Julie",
      title: "Professeur de danse Modern Jazz",
      description: "Danseuse de formation jazz, classique et contemporain. Formée dans le cursus pré-professionnel à Arts K Danse (13). Julie a été médaillée d’or en soliste et en groupe à de nombreux concours nationaux et internationaux.",
      socialLinks: [
        { href: "#", icon: "icon-social-facebook" },
        { href: "#", icon: "icon-social-twitter" },
        { href: "#", icon: "icon-social-instagram" },
      ]
    },
    {
      imgSrc: t5,
      name: "Adil",
      title: "Professeur de Hip Hop",
      description: "Animateur, danseur spécialisé en danse urbaine et Hip Hop, j’aime travailler dans un environnement créatif qui permet aux enfants de se découvrir tout en apprenant les bases et techniques de la danse.",
      socialLinks: [
        { href: "#", icon: "icon-social-facebook" },
        { href: "#", icon: "icon-social-twitter" },
        { href: "#", icon: "icon-social-instagram" },
      ]
    },
    {
      imgSrc: t6,
      name: "Anne-Sophie",
      title: "Chant",
      description: "Anne-Sophie est chanteuse professionnelle. Accréditée par les Musiciens du Métro mais également par la Préfecture de Police en tant qu’Artiste de rue à Montmartre, elle se produit sur de nombreuses scènes parisiennes pour des évènements privés et publics.",
      socialLinks: [
        { href: "#", icon: "icon-social-facebook" },
        { href: "#", icon: "icon-social-twitter" },
        { href: "#", icon: "icon-social-instagram" },
      ]
    },
    {
      imgSrc: t7,
      name: "Loundjah",
      title: "Danse Orientale",
      description: "Loundjah est chorégraphe, danseuse, et enseignante. Elle a obtenu son Examen d’Aptitude Technique en danse contemporaine en 2013 puis une certification de formateur en danses orientale et orientale contemporaine en 2015 par l’école Assia Guemra.",
      socialLinks: [
        { href: "#", icon: "icon-social-facebook" },
        { href: "#", icon: "icon-social-twitter" },
        { href: "#", icon: "icon-social-instagram" },
      ]
    },
    {
      imgSrc: t9,
      name: "Adrien Pasternak",
      title: "Coach sportif, spécialisé en danses de Guinée et Pilates",
      description: "Adrien Pasternak organise vos activités sportives avec professionnalisme et enthousiasme. Déterminé à atteindre un seul objectif : le votre !  Fort de huit années d’expérience, Adrien enseigne le renforcement musculaire (cuisses abdos fessiers, body sculpt, body barre…), les techniques douces (gym zen, Pilates, stretching…) et cardio (hitt, cross training, circuits training, afro dance). Il est titulaire d’un BPJEPS Activités de la forme (2018) et d’un CQP ALS AGEE (2017). Que vous soyez débutant(e)s ou confirmé(e)s, vous êtes les bienvenu(e)s ! ",
      socialLinks: [
        { href: "#", icon: "icon-social-facebook" },
        { href: "#", icon: "icon-social-twitter" },
        { href: "#", icon: "icon-social-instagram" },
      ]
    },
    {
      imgSrc: t8,
      name: "Gabrielé",
      title: "Salsa Cubaine",
      description: "Découvrez les rythmes vibrants et les mouvements captivants de la Salsa Cubaine, une danse sociale dynamique originaire de Cuba. Ce cours s'adresse à tous les niveaux, des débutants aux danseurs avancés. Apprenez les pas de base, les techniques de danse en couple, et ajoutez du style et de l'improvisation à vos mouvements. Rejoignez-nous pour une expérience de danse enrichissante et plongez dans l'énergie et la culture cubaine!",
      socialLinks: [
        { href: "#", icon: "icon-social-facebook" },
        { href: "#", icon: "icon-social-twitter" },
        { href: "#", icon: "icon-social-instagram" },
      ]
    }
  ];

  return (
    <div className="py-5 team4">
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="col-md-7 text-center">
            <h3 className="mb3">Équipe Expérimentée et Professionnelle</h3>
            <h6 className="subtitle">Vous pouvez compter sur notre liste de fonctionnalités étonnantes et nos services client seront une expérience formidable pour vous, sans aucun doute et en un rien de temps.</h6>
          </div>
        </div>
        <div className="row">
          {teamMembers.map((member, index) => (
            <div className="col-lg-3 mb-4" key={index}>
              <div className="row team-member">
                <div className="col-md-12">
                  <img src={member.imgSrc} alt={member.name} className="img-fluid rounded-circle" />
                </div>
                <div className="col-md-12 text-center">
                  <div className="pt-2">
                    <h5 className="mt-4 font-weight-medium mb-0">{member.name}</h5>
                    <h6 className="subtitle mb-3">{member.title}</h6>
                    <p>{member.description}</p>
                    <ul className="list-inline">
                      {member.socialLinks.map((link, i) => (
                        <li className="list-inline-item" key={i}>
                          <a href={link.href} className="text-decoration-none d-block px-1"><i className={link.icon}></i></a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
