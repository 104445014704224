import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Adulte from './components/Adulte';
import ModernJazz from './components/ModernJazz';
import DanseOrientale from './components/DanseOrientale';
import Chant from './components/Chant';
import AthelierDeTheatre from './components/AthelierDeTheatre';
import SalsaCubaine from './components/SalsaCubaine';
import Anniversaire from './components/Anniversaire';
import Contact from './components/Contact';
import Login from './components/Login';
import Enfant from './components/Enfant';
import Gymdouce from './components/Gymdouce';
import Team from './components/Team';
import Signup from './components/Signup';
import Yoga from './components/Yoga';
import Tarif from './components/Tarif';
import Enfantdansechoregraphie from './components/Enfantdansechoregraphie';
import Enfanthiphop from './components/Enfanthiphop';
import Enfantdanseclassique from './components/Enfandaneclassique';
import Chantenfant from './components/Chantenfant';
import Enfantmodernejazz from './components/Enfantmodernejazz';
import Atheliertheatreenfant from './components/Atheliertheatreenfant';
import Bachta from './components/Bachta';
import Dansedumonde from './components/Dansedumonde';
import Schedule from './components/Schedule';
import Payment from './components/Payment';
import Barreausol from './components/Barreausol' ;
import Pilate from './components/Pilate';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Studio from './components/Studio';

const App = () => {
  const location = useLocation();

  return (
    <div className="App">
      <Header />
      {location.pathname === '/' && <Hero />}
      <main>
        <Routes>
        <Route path="/Enfantdansechoregraphie" element={<Enfantdansechoregraphie />} />
        <Route path="/Enfanthiphop" element={<Enfanthiphop />} />
        <Route path="/Enfantdanseclassique" element={<Enfantdanseclassique />} />
        <Route path="/Enfantmodernejazz" element={<Enfantmodernejazz />} />
        <Route path="/Chantenfant" element={<Chantenfant />} />
        <Route path="/Bachta" element={<Bachta />} />
        <Route path="/Dansedumonde" element={<Dansedumonde />} />

        <Route path="/Barreausol" element={<Barreausol />} />



          <Route path="/" element={<Home />} />
          <Route path="/adulte" element={<Adulte />} />
          <Route path="/enfants" element={<div>Enfants Classes Page</div>} /> {/* Placeholder */}
          <Route path="/contact" element={<Contact />} /> {/* Fixed placeholder */}
          <Route path="/client" element={<div>Client Page</div>} /> {/* Placeholder */}
          <Route path="/Modernjazz" element={<ModernJazz />} />
          <Route path="/DanseOrientale" element={<DanseOrientale />} /> {/* Fixed placeholder */}
          <Route path="/chant" element={<Chant />} />
          <Route path="/AthelierDeTheatre" element={<AthelierDeTheatre />} />
          <Route path="/Atheliertheatreenfant" element={<Atheliertheatreenfant/>} />
          <Route path="/Pilate" element={<Pilate />} />

          <Route path="/SalsaCubaine" element={<SalsaCubaine />} />
          <Route path="/login" element={<Login />} />
          <Route path="/enfant" element={<Enfant />} />
          <Route path="/team" element={<Team />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/tarif" element={<Tarif />} />
          <Route path="/Schedule" element={<Schedule />} />
          <Route path="/anniversaire" element={<Anniversaire />} />
          <Route path="/Gymdouce" element={<Gymdouce />} />
          <Route path="/Yoga" element={<Yoga />} />

          <Route path="/Studio" element={<Studio />} />

        </Routes>
      </main>
      <Footer />
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
