import React from 'react';
import '../styles/Adulte.css';
import { Link } from 'react-router-dom';

import modernJazzImage from '../Images/danseenfant.png';
import danseOrientaleImage from '../Images/DANSE ORIENTALE.jpg';
import chantImage from '../Images/danse1.jpg';
import theatreImage from '../Images/chant.jpg';
import salsaImage from '../Images/cher2.jpg';
import anniversaireImage from '../Images/anniversaireenfant.jpg'; // Add the image for the new category
import theatre from '../Images/kidthe1.jpg'; // Add the image for the new category
import Enfanthiphop from '../Images/hiohopenfant.jpg';
const categories = [
  {
    title: 'Enfant-danse chorégraphie',
    description: 'Découvrez l\'énergie et l\'expression du Modern Jazz.',
    image: modernJazzImage,
    link: '/Enfantdansechoregraphie'
  },
  {
    title: 'Enfant hip-hop',
    description: 'Plongez dans l\'univers dynamique et créatif du Hip-Hop.',
    image: Enfanthiphop,
    link: '/Enfanthiphop'
  },
  
  
  {
    title: 'Enfant modern jazz',
    description: 'Ressentez le rythme et la joie du Modern Jazz.',
    image: salsaImage,
    link: '/Enfantmodernejazz'
  },
  {
    title: 'Anniversaire',
    description: 'Célébrez votre anniversaire avec une fête inoubliable!',
    image: anniversaireImage,
    link: '/anniversaire'
  },
  {
    title: 'Ateliers Théâtre',
    description: 'Libérez votre potentiel d\'acteur avec nos ateliers de théâtre.',
    image: theatre,
    link: '/Atheliertheatreenfant'
  }
];

const Enfant = () => {
  return (
    <div>
      <main className="adulte-main">
        <h1 className="adulte-title">Activités Enfants</h1>
        <div className="categories-container">
          {categories.map((category, index) => (
            <div className="category-card" key={index}>
              <div className="image-overlay">
                <img src={category.image} alt={category.title} className="category-image" />
              </div>
              <div className="category-content">
                <h2 className="category-title">{category.title}</h2>
                <p className="category-description">{category.description}</p>
                <div className="category-buttons">
                  <Link to={category.link} className="category-button">Découvrir</Link>
                  <a href="Schedule" className="category-button reserve">Réserver</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Enfant;